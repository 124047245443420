<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-toolbar
      color="deep-purple accent-4"
      dark
    >
      <v-toolbar-title>User Management</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="dialog = true">
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list subheader>
      <v-subheader>User</v-subheader>

      <v-list-item
        v-for="user in users"
        :key="user.user_id"
        link
      >
        <v-list-item-avatar>
          <v-img
            :alt="`${user.user_name} avatar`"
            :src="`${user.user_avatar || 'https://infinitrader-file.quantdo.com.cn/avatar/default_avatar.png'}!avatar`"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            v-html="`${user.user_name} ${user.user_role === 1 ? adminTitle : ''}`">
            
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-on:click.stop.prevent="onEdit(user)">
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-account-edit</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
        v-model="page"
        :length="6"
        :total-visible="4"
      ></v-pagination>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.user_name"
                  label="用户名*"
                  :rules="[v => !!v || '用户名是必填的']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.user_password"
                  label="密码*"
                  :rules="edit ? [] : [v => !!v || '密码呢？']"
                  hint="新建用户时必填"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.user_role"
                  :items="userRoles"
                  label="权限" required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="form.user_status"
                  :items="userStatus"
                  item-text="text"
                  item-value="value"
                  label="用户状态"
                  required>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-file-input
                  v-model="form.user_avatar"
                  :rules="[value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!']"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="选择头像"
                  prepend-icon="mdi-camera"
                  label="头像"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
          <small>* 表示必填</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="creatUser">{{ btnText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar :snackbar="snackbar"></SnackBar>
  </v-card>
</template>

<script>
import SnackBar from './SnackBar.vue'
import http, { AuthHttp } from '../plugins/http'
  export default {
    components: {
      SnackBar
    },
    data: () => ({
      snackbar: {
        show: false,
        text: '',
        timeout: 3000
      },
      valid: false,
      page: 1,
      dialog: false,
      userStatus: [{text: '正常', value: '1'}, {text: '禁用', value: '0'}],
      userRoles: [],
      form: {},
      defaultItem: {
        user_name: '',
        user_password: '',
        user_role: null,
        user_status: '1',
        user_avatar: null
      },
      edit: false,
      users: [],
      adminTitle: '<span class="grey--text text--lighten-1">管理员</span>'
    }),
    mounted () {
      this.getUsers()
      this.getUserRoles()
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    methods: {
      showSnackBar (text) {
        this.snackbar.show = true
        this.snackbar.text = text
        setTimeout(() => {
          this.snackbar.text
        }, this.snackbar.timeout)
      },
      close () {
        this.$nextTick(() => {
          this.dialog = false
          this.edit = false
          this.form = Object.assign({}, this.defaultItem)
          this.$refs.form && this.$refs.form.resetValidation()
        })
      },
      onEdit (item) {
        console.log(item)
        this.edit = true
        this.form.user_name = item.user_name
        this.form.user_role = item.user_role
        this.form.user_status = item.user_status
        this.dialog = true
      },
      getUsers () {
        http.get('/get/users').then(res => {
          this.users = res.data
        })
      },
      creatUser () {
        if (!this.$refs.form.validate()) return
        if (this.edit) {
          this.showSnackBar("编辑成功")
        } else {
          AuthHttp.post("/creat_user", {...this.form})
            .then((res) => {
              console.log(this.users, typeof this.users)
              this.users.push(this.form)
              this.form = Object.assign({}, this.defaultItem)
              this.$refs.form.resetValidation()
              this.showSnackBar(res.msg)
              this.close()
            })
            .catch(e => {console.log(e)})
        }
      },
      getUserRoles () {
        http.get("/get/user_roles").then(res => {
          this.userRoles = res.data
        })
      }
    },
    computed: {
      btnText () {
        return this.edit ? "修改" : "新增"
      }
    }
  }
</script>
